.split-line {
    line-height: 120%;
}

.split-line a {
    display: inline-block;
    border-bottom: 2px solid #000;
    transition: all 200ms ease-in-out;
}

.split-line a:hover {
    opacity: .5;
}