@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'Neue Machina';
    src: url('./assets/fonts/NeueMachina-Ultrabold.otf') format('otf');
    font-weight: bold;
    font-style: bold;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: #0A0A0A;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
}

header {
    background-image: radial-gradient(rgba(20, 18, 18, 0) 1px, #fff 1px);
    background-size: 9px 9px;
    backdrop-filter: blur(10px);
}